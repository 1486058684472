import React from 'react';

const PrivacyPolicy = () => (
  <div className="container my-5">
    <h1>Geists, LLC</h1>
    <h2 className="mt-4">Effective Date: December 9, 2024</h2>

    <h2 className="mt-5">Privacy Policy</h2>
    <p>
      Geists, LLC is committed to protecting your privacy. This policy explains how we collect, use, and share your information.
    </p>

    <h3 className="mt-4">1. Information We Collect</h3>
    <ul>
      <li>
        <strong>Personal Identification Information:</strong> Email, age, gender, and voluntary information.
      </li>
      <li>
        <strong>Non-Personal Information:</strong> Device type, IP address, and usage patterns.
      </li>
      <li>
        <strong>Behavioral Data:</strong> Interaction data for personalized experiences.
      </li>
    </ul>

    <h3 className="mt-4">2. How We Use Your Information</h3>
    <ul>
      <li>To personalize challenges and recommendations.</li>
      <li>To analyze trends and improve app features.</li>
      <li>To provide updates and customer support.</li>
      <li>To detect and address security issues.</li>
    </ul>

    <h3 className="mt-4">3. Data Sharing</h3>
    <ul>
      <li>
        <strong>We Do Not Sell Data:</strong> Your information is never sold or rented.
      </li>
      <li>
        <strong>Third-Party Services:</strong> For analytics and secure payment processing.
      </li>
      <li>
        <strong>Legal Requirements:</strong> Compliance with applicable laws.
      </li>
    </ul>

    <h3 className="mt-4">4. Your Privacy Rights</h3>
    <ul>
      <li>
        <strong>Access:</strong> Request a copy of your data.
      </li>
      <li>
        <strong>Correction:</strong> Update your personal information.
      </li>
      <li>
        <strong>Deletion:</strong> Delete your account and associated data.
      </li>
    </ul>

    <h3 className="mt-4">Contact Us</h3>
    <p>
      <strong>Email:</strong> support@geists.app
    </p>
    <p>
      <strong>Address:</strong> 8 The Green #8416 Dover, DE, 19901, United States
    </p>
  </div>
);

export default PrivacyPolicy;
