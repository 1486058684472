import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import GeistLogo from '../images/Geist_Logo.png';
import HandSituationGuide from '../images/Hand_Relationships.png';

import HandCall from '../images/Hand_Call.png';
import HandCard from '../images/Hand_Card.png';
import HandDecode from '../images/Hand_Decode.png';
import HandDatePrep from '../images/Hand_DatePrep.png';
import './Home.css';

const Home = () => {
  const images = [HandSituationGuide, HandCall, HandCard, HandDecode, HandDatePrep];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [email, setEmail] = useState('');
  const [optIn, setOptIn] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  const handleSubscribe = async () => {
    if (isSubmitting) return; // Prevent double submissions
  
    if (!email || !optIn) {
      setSubmitStatus('Please provide your email and agree to the terms.');
      return;
    }
  
    setIsSubmitting(true);
    const functions = getFunctions();
    const subscribeToKlaviyo = httpsCallable(functions, 'subscribeToKlaviyo');
  
    try {
      const response = await subscribeToKlaviyo({ email, hasConsented: optIn });
      setSubmitStatus(
        response.data.success
          ? 'Success! Check your inbox for the invite.'
          : 'Oops! Something went wrong. Please try again later.'
      );
    } catch (error) {
      console.error('Error subscribing:', error);
      setSubmitStatus('Subscription failed. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <div style={{ backgroundColor: '#EBEBEB' }} className="d-flex flex-column justify-content-between min-vh-100">
      <div className="container-fluid text-center" style={{ padding: '0 0px' }}>
        {/* Header */}
        <div className="mb-4 mt-4 text-center">
          <div className="d-flex justify-content-center align-items-center gap-2 mb-4">
            <img src={GeistLogo} alt="Geists Logo" className="me-2" style={{ width: '72px', height: '72px' }} />
            <h1 className="display-4 text-black font-serif mb-0" style={{ fontFamily: 'Clifton, serif' }}>
              Ge<span style={{ fontStyle: 'italic' }}>i</span>sts
            </h1>
          </div>
          <div style={{marginLeft: '12px', marginRight: '12px'}}>
          <h2 className="h5 font-serif mb-4 text-black py-4" style={{ fontFamily: 'Merriweather, serif', fontWeight: '200', maxWidth: '800px', margin: 'auto', lineHeight: '1.4', letterSpacing: '0px', margin: '0 auto', marginTop:'-16px'}}>
            Your secret weapon for serendipity, awkward situationships, red flag dates, and confusing energy.
          </h2>
          </div>
        </div>

        {/* Main Content */}
        <div className="row g-0" style={{marginLeft:'8px', marginRight:'8px', marginTop:'-16px'}}>
          {/* Form Section */}
          <div className="col-md-5 d-flex align-items-start order-1 order-md-2 form-container" style={{ maxWidth: '500px' }}>
            <div className="p-3 rounded-3 shadow-md" style={{ backgroundColor: '#EAE0FD', border: '1px solid black' }}>
              <h3 className="text-black font-serif mb-3 text-start" style={{ fontFamily: 'Merriweather, serif', fontSize: '1.1rem', fontWeight: '100', letterSpacing: '-1px', lineHeight:'1.6rem' }}>
                Part card game, part situation guide, part escape route - for bold IRL moves with a backup plan 😈
              </h3>

              <form onSubmit={(e) => { e.preventDefault(); handleSubscribe(); }}>
                <div className="mb-2 text-start">
                  <label htmlFor="email" className="form-label" style={{ color: 'black' }}>
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ backgroundColor: 'white', color: 'black', maxWidth: '328px', height: '54px' }}
                  />
                </div>
                <div className="mb-2 text-start checkbox-container">
  <input
    type="checkbox"
    id="optIn"
    className="form-check-input"
    checked={optIn}
    onChange={(e) => setOptIn(e.target.checked)}
    required
  />
  <label
    htmlFor="optIn"
    className="form-check-label"
    aria-label="I agree to receive marketing from Geists"
  >
    I agree to receive marketing from Geists
  </label>
</div>

                <div className="d-flex justify-content-start">
                <button
  type="submit"
  className="btn btn-warning text-black fw-light button-hover-effect"
  style={{
    borderRadius: '100px',
    fontFamily: 'Inter, serif',
    fontWeight: '200',
    border: '1px solid black',
    padding: '12px 24px',
    marginTop: '8px',
    fontSize: '1.1rem',
    maxWidth: '300px',
  }}
  disabled={isSubmitting}
>
  {isSubmitting ? 'Submitting...' : 'Join the Beta'} <span style={{ marginLeft: '4px' }} className="arrow"> →</span>
</button>

                </div>
                {submitStatus && (
  <div
    className="success-message mt-3"
    style={{
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'white',
      border: '1px solid #b6b6b6', // Very light grey border
      borderRadius: '8px',
      padding: '12px',
      maxWidth: '328px', // Increased width
      marginLeft: '0', // Aligns the message container to the left
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      textAlign: 'left', // Ensures text aligns to the left
    }}
  >
    {submitStatus.includes('Success') && (
      <i
        className="bi bi-check-circle-fill text-success"
        style={{
          fontSize: '24px',
          marginRight: '16px',
        }}
      ></i>
    )}
    <p
      style={{
        margin: 0,
        color: submitStatus.includes('Success') ? 'black' : 'red',
        fontWeight: '600',
        fontSize: '14px',
      }}
    >
      {submitStatus}
    </p>
  </div>
)}


              </form>
            </div>
          </div>

          {/* Image Section */}
          <div className="col-md-7 d-flex justify-content-center align-items-center order-2 order-md-1" style={{}}>
            <img
              src={images[currentImageIndex]}
              alt="Hand holding iPhone mockup with Geists app"
              className="img-fluid"
              style={{ maxWidth: '100%' }}
            />
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="d-flex flex-column align-items-left" style={{ backgroundColor: '#000000', color: 'white', padding: '2rem 0', width: '100%', fontFamily: 'Inter, sans-serif' }}>
        <div style={{ marginLeft: '12px', padding: '12px' }}>
          <a href="mailto:support@geists.app" className="text-decoration-underline text-secondary mb-2">
            Contact us
          </a>
          <p className="mb-1"></p>
          <p className="mb-0">Geists, LLC</p>
          <p className="mb-0">8 The Green # 8416, Dover, DE 19901</p>
          <p className="mb-0">+1 (302) 927-1836</p>
          <div className="mt-3">
      <a href="/privacy-policy" className="text-decoration-underline text-secondary me-3">
        Privacy Policy
      </a>
      <a href="/terms-and-conditions" className="text-decoration-underline text-secondary">
        Terms and Conditions
      </a>
    </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
