import React from 'react';

const TermsAndConditions = () => (
  <div className="container my-5">
    <h1>Geists, LLC</h1>
    <h2 className="mt-4">Effective Date: December 9, 2024</h2>

    <h2 className="mt-5">Terms and Conditions</h2>
    <p>
      By accessing or using Geists, you agree to these Terms and Conditions. If you do not agree to these conditions, do not use the app or its services.
    </p>

    <h3 className="mt-4">1. Acceptance of Terms</h3>
    <p>
      By accessing or using Geists, you agree to these Terms and Conditions. If you do not agree to these conditions, do not use the app or its services.
    </p>

    <h3 className="mt-4">2. Use License</h3>
    <p>
      Geists grants you a limited, non-transferable license to use the app for personal, non-commercial purposes. You may not copy, modify, distribute, or reverse-engineer any part of the app.
    </p>

    <h3 className="mt-4">3. Subscription and Billing</h3>
    <p>
      Subscriptions auto-renew unless canceled 24 hours before the current period ends. Renewal charges will be applied to your account within 24 hours before the end of the billing cycle.
    </p>

    <h3 className="mt-4">4. User Responsibilities</h3>
    <p>
      Use Geists responsibly and in compliance with applicable laws. Do not post or share inappropriate, harmful, or illegal content within the app.
    </p>

    <h3 className="mt-4">5. Disclaimer</h3>
    <p>
      Geists is provided "as is" without any guarantees or warranties. We disclaim all liability for damages arising from your use of the app, including but not limited to loss of data or interruptions.
    </p>

    <h3 className="mt-4">6. Limitation of Liability</h3>
    <p>
      Geists and its team are not responsible for any indirect, incidental, or consequential damages resulting from the use of our services. This includes, but is not limited to:
    </p>
    <ul>
      <li>Reliance on AI-generated insights or recommendations.</li>
      <li>Outcomes or actions resulting from challenges, tasks, or interactions with the app.</li>
      <li>Issues arising from the use, inability to use, or performance of the services.</li>
    </ul>
    <p>
      Geists is designed as an educational and self-improvement tool and is not a substitute for professional advice, counseling, or medical services. Users should exercise personal judgment and seek appropriate professional assistance as needed.
    </p>

    <p className="text-danger">
      Geists is not a substitute for professional mental health or medical advice. If you or someone you know is struggling with thoughts of self-harm or suicide, help is available. Please reach out to a trusted friend, family member, or a mental health professional. You can also contact a free, confidential support line available 24/7:
    </p>
    <ul className="text-danger">
      <li>USA: Call or text 988 (988 Suicide & Crisis Lifeline)</li>
      <li>UK: Call 116 123 (Samaritans)</li>
      <li>Canada: Call 1-833-456-4566 (Talk Suicide Canada)</li>
    </ul>
    <p className="text-danger">You are not alone, and there are people who care and want to help.</p>

    <h3 className="mt-4">7. Modifications to the Terms</h3>
    <p>
      Geists may update these Terms of Service without prior notice. Continued use of the app constitutes acceptance of the modified terms.
    </p>

    <h3 className="mt-4">8. Governing Law</h3>
    <p>
      These terms are governed by the laws applicable in the user’s location, and disputes will be resolved through binding arbitration or local dispute resolution processes as required.
    </p>

    <h3 className="mt-4">9. Contact Us</h3>
    <p>
      If you have any questions about these Terms of Service, please contact us:
    </p>
    <p>
      <strong>Email:</strong> support@geists.app
    </p>
    <p>
      <strong>Mailing Address:</strong> Geists, LLC, 8 The Green #8416 Dover, DE, 19901, United States
    </p>
  </div>
);

export default TermsAndConditions;
